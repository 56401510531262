import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";

// import StyledSectionWrapper from "../shared/StyledSectionWrapper";
import StyledContainerWrapper from "../shared/StyledContainerWrapper";
import { Icon } from "../../globals/icons";
import {
  StyledSubTitle2,
  StyledBody1,
  StyledDesc1,
  StyledDesc2,
  StyledDesc3,
} from "../shared/StyledTypography";
import { COLORS } from "../../globals/designSystem";
import { flexCenter, textCenter } from "../shared/CommonStyled";
import { scrollToTop } from "../../utils/helpers";
import { mq } from "../../globals/utils";
import { Flex, Image } from "../../globals/UIKit";

export const StyledUpcomingEventWrapper = styled.div`
  margin-top: -270px;
  position: relative;
  .upcomingEventHeader {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 48px;
    opacity: 0.8;
    /* .regTag {
      background: rgba(255, 255, 255, 0.23);
      border: 1px solid #d2d6db;
      border-radius: 40px;
      display: flex;
      align-items: center;
      padding: 9px 16px;
      margin-left: 22px;
      svg {
        margin-right: 8px;
      }
    } */
  }
  .upcomingEventListWrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 50px;
    padding-bottom: 48px;
    .upcomingEventList {
      transition: all 0.3s ease-in-out;
      border: 1px solid #d2d6db;
      border-radius: 12px;
      position: relative;

      max-width: 531px;

      &:hover {
        box-shadow: 0px 20px 24px -4px rgba(28, 38, 63, 0.08),
          0px 8px 8px -4px rgba(28, 38, 63, 0.03);
        .eventDetails {
          .rightIcon {
            transform: translateX(5px);
          }
        }
      }
      .eventImg {
        img {
          width: calc(100% + 1px);
          max-width: calc(100% + 1px);
          border-radius: 11px 11px 0 0;
          /* max-height: 301px; */
        }
      }
      /* .ticketTag {
        background: rgba(243, 244, 246, 0.5);
        border-radius: 100px;
        padding: 2px 10px;
        position: absolute;
        top: 40px;
        left: 40px;
      } */
      .eventDetails {
        padding: 32px 40px;
        .rightIcon {
          transition: all 0.3s ease-in-out;
          margin-left: 4px;
        }
      }
    }
  }

  .single-grid {
    display: grid;
    grid-template-columns: 1fr !important;
  }

  .tooFarTagWrapper {
    background: ${COLORS.neutral_100};
    border-radius: 100px;
    padding: 8px 10px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    a {
      color: ${COLORS.blue_600};
      &:hover {
        color: ${COLORS.blue_700};
      }
    }
    .tooFarTag {
      background: ${COLORS.white};
      border-radius: 100px;
      padding: 2px 10px;
      margin-right: 8px;
    }
    .mobileShoweCont {
      display: none;
    }
    .rightRotateIcon {
      transform: rotate(-45deg);
      margin-left: 8px;
    }
  }
  ${mq.below.md} {
    .upcomingEventHeader {
      flex-direction: column;
      /* .regTag {
        margin-left: 0;
        margin-top: 16px;
        display: inline-flex;
      } */
    }
    .upcomingEventListWrapper {
      grid-template-columns: 1fr;
      .upcomingEventList {
        /* .ticketTag {
          left: 16px;
          top: 16px;
        } */
      }
    }
    .tooFarTagWrapper {
      .mobileShoweCont {
        display: block;
      }
      .mobileHideCont {
        display: none;
      }
    }
  }
`;

// const Sale = () => (
//   <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
//     <path
//       d="M9 9H9.01M15 15H15.01M16 8L8 16M9.2019 20.6009C9.52965 20.5575 9.86073 20.6464 10.1218 20.8475L11.3251 21.7708C11.7228 22.0764 12.2761 22.0764 12.6727 21.7708L13.9215 20.812C14.1548 20.6331 14.4492 20.5542 14.7403 20.5931L16.3024 20.7986C16.799 20.8642 17.2779 20.5875 17.4701 20.1242L18.0712 18.6709C18.1834 18.3987 18.3989 18.1832 18.6711 18.0709L20.1243 17.4698C20.5876 17.2787 20.8643 16.7988 20.7987 16.3021L20.601 14.7966C20.5576 14.4688 20.6465 14.1377 20.8476 13.8766L21.7709 12.6733C22.0764 12.2755 22.0764 11.7222 21.7709 11.3256L20.812 10.0767C20.6332 9.84339 20.5543 9.54896 20.5932 9.25785L20.7987 7.69568C20.8643 7.19902 20.5876 6.72015 20.1243 6.52793L18.6711 5.92684C18.3989 5.81462 18.1834 5.59907 18.0712 5.32685L17.4701 3.87356C17.279 3.41024 16.799 3.13358 16.3024 3.19913L14.7403 3.40468C14.4492 3.44468 14.1548 3.36579 13.9226 3.18802L12.6738 2.22916C12.2761 1.92361 11.7228 1.92361 11.3262 2.22916L10.0774 3.18802C9.84407 3.36579 9.54965 3.44468 9.25856 3.40691L7.69647 3.20136C7.19984 3.1358 6.721 3.41246 6.52879 3.87578L5.92884 5.32907C5.81552 5.60018 5.59998 5.81573 5.32889 5.92906L3.87568 6.52904C3.41238 6.72126 3.13574 7.20013 3.20129 7.69679L3.40683 9.25897C3.4446 9.55007 3.36572 9.8445 3.18796 10.0767L2.22915 11.3256C1.92362 11.7233 1.92362 12.2767 2.22915 12.6733L3.18796 13.9222C3.36683 14.1555 3.44571 14.4499 3.40683 14.741L3.20129 16.3032C3.13574 16.7999 3.41238 17.2787 3.87568 17.471L5.32889 18.0721C5.60109 18.1843 5.81663 18.3998 5.92884 18.672L6.5299 20.1253C6.721 20.5887 7.20096 20.8653 7.69758 20.7998L9.2019 20.6009ZM9.5 9C9.5 9.27614 9.27614 9.5 9 9.5C8.72386 9.5 8.5 9.27614 8.5 9C8.5 8.72386 8.72386 8.5 9 8.5C9.27614 8.5 9.5 8.72386 9.5 9ZM15.5 15C15.5 15.2761 15.2761 15.5 15 15.5C14.7239 15.5 14.5 15.2761 14.5 15C14.5 14.7239 14.7239 14.5 15 14.5C15.2761 14.5 15.5 14.7239 15.5 15Z"
//       stroke="white"
//       stroke-width="1.5"
//       stroke-linecap="round"
//       stroke-linejoin="round"
//     />
//   </svg>
// );

const UpcomingEvents = () => {
  return (
    <StyledContainerWrapper>
      <StyledUpcomingEventWrapper>
        <div className="upcomingEventHeader">
          <StyledBody1 variant="white" fontWeight="font_400">
            Past Events
          </StyledBody1>
        </div>
        <div className="upcomingEventListWrapper">
          <Link
            to="/data-api-days/virtual-apac/"
            className="upcomingEventList"
            onClick={scrollToTop}
          >
            <div className="eventImg">
              <Image
                src="https://res.cloudinary.com/dh8fp23nd/image/upload/v1683792815/main-web/dapi-virtual-listing-image_1_qm5bfe.jpg"
                alt="Virtual Data + API Days"
              />
            </div>
            <div className="eventDetails">
              <StyledSubTitle2 variant="neutral_800" fontWeight="font_bold">
                Virtual Data + API Days
              </StyledSubTitle2>
              <StyledDesc1 variant="neutral_800" paddingBottom="pb40" fontWeight="font_500">
                Wednesday, May 31
              </StyledDesc1>
              <StyledDesc2 variant="neutral_1000" css={flexCenter} fontWeight="font_500">
                Recordings Coming Soon
                <Icon
                  className="rightIcon"
                  variant="chevron_right"
                  size="md"
                  color="neutral_1000"
                />
              </StyledDesc2>
            </div>
          </Link>
          <Link
            to="/data-api-days/san-francisco/"
            className="upcomingEventList"
            onClick={scrollToTop}
          >
            <div className="eventImg">
              <Image
                src="https://res.cloudinary.com/dh8fp23nd/image/upload/v1675677393/main-web/roadshow/sf_cgff5z.png"
                alt="San Francisco"
              />
            </div>
            <div className="eventDetails">
              <StyledSubTitle2 variant="neutral_800" fontWeight="font_bold">
                San Francisco, CA
              </StyledSubTitle2>
              <StyledDesc1 variant="red_80" paddingBottom="pb40" fontWeight="font_500">
                Event Concluded
              </StyledDesc1>
              <StyledDesc2 variant="neutral_1000" css={flexCenter} fontWeight="font_500">
                Recordings Coming Soon
                <Icon
                  className="rightIcon"
                  variant="chevron_right"
                  size="md"
                  color="neutral_1000"
                />
              </StyledDesc2>
            </div>
          </Link>
        </div>
        <Flex width="100%" justifyContent="center">
          <div className="upcomingEventListWrapper single-grid">
            <Link to="/data-api-days/new-york/" className="upcomingEventList" onClick={scrollToTop}>
              <div className="eventImg">
                <Image
                  src="https://res.cloudinary.com/dh8fp23nd/image/upload/v1675677222/main-web/roadshow/ny_kq1drw.png"
                  alt="New York"
                />
              </div>
              {/*<StyledDesc2 variant="neutral_700" className="ticketTag" fontWeight="font_500">
              Tickets at $59
            </StyledDesc2>
            */}
              <div className="eventDetails">
                <StyledSubTitle2 variant="neutral_800" fontWeight="font_bold">
                  New York, NY
                </StyledSubTitle2>
                <StyledDesc1 variant="red_80" paddingBottom="pb40" fontWeight="font_500">
                  Event Concluded
                </StyledDesc1>
                <StyledDesc2 variant="neutral_1000" css={flexCenter} fontWeight="font_500">
                  Recordings Coming Soon
                  <Icon
                    className="rightIcon"
                    variant="chevron_right"
                    size="md"
                    color="neutral_1000"
                  />
                </StyledDesc2>
              </div>
            </Link>
          </div>
        </Flex>
        <div css={textCenter}>
          <div className="tooFarTagWrapper">
            <StyledDesc3 className="tooFarTag" variant="neutral_700" fontWeight="font_500">
              Too Far?
            </StyledDesc3>
            <StyledDesc3 className="mobileHideCont" variant="neutral_1000" fontWeight="font_500">
              <a
                href="mailto:events@hasura.io?subject=City you’d like to see us in next"
                target="_top"
                css={flexCenter}
              >
                Tell us which city you’d like to see us in next{" "}
                <Icon
                  className="rightRotateIcon"
                  variant="arrow_right"
                  size="md"
                  color="blue_600"
                />
              </a>
            </StyledDesc3>
            <StyledDesc3 className="mobileShoweCont" variant="neutral_1000" fontWeight="font_500">
              <a
                href="mailto:events@hasura.io?subject=City you’d like to see us in next"
                target="_top"
                css={flexCenter}
              >
                Tell us which city{" "}
                <Icon
                  className="rightRotateIcon"
                  variant="arrow_right"
                  size="md"
                  color="blue_600"
                />
              </a>
            </StyledDesc3>
          </div>
        </div>
      </StyledUpcomingEventWrapper>
    </StyledContainerWrapper>
  );
};

export default UpcomingEvents;
